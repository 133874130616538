import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonIcon,
	useIonViewDidEnter,
	useIonViewWillEnter,
	useIonViewWillLeave
} from "@ionic/react";
import useOnlineStatus from "@rehooks/online-status";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import useFetch from "use-http";
import HttpError from "../components/HttpError";
import NoConnection from "../components/NoConnection";
import PageLoader from "../components/PageLoader";
import WpPost from "../components/WpPost";
import { WordpressPageJson } from "../types";
import styles from './Info.module.css';
import looolLogoIcon from '../theme/icons/loool-logo.svg';
import externalLinkIcon from '../theme/icons/external-link.svg';
import classNames from "classnames";

const Info: React.FC<RouteComponentProps> = () => {
	const onlineStatus = useOnlineStatus();
	const { t, i18n } = useTranslation();
	const {
		loading,
		error,
		data = null,
		get,
		abort,
	} = useFetch<WordpressPageJson>(
		`${process.env.REACT_APP_REST_API_DOMAIN}/backend/${i18n.language}/wp-json/pwa/v1/getInfoPage`,
		{
			retries: 3,
		}
	);

	useEffect(() => {
		if (onlineStatus) {
			get();
		}
	}, [onlineStatus, get]);

	useIonViewWillEnter(() => {
		if (loading) {
			return;
		}

		get();
	}, [loading, get]);

	useIonViewDidEnter(() => {
		document.title = t("pages.info.title");
	});

	useIonViewWillLeave(() => {
		abort();
	}, [abort]);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle slot="start">{t("pages.info.title")}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent scrollEvents={true} fullscreen={true}>
				{!onlineStatus && <NoConnection />}
				{onlineStatus && (
					<>
						{loading && <PageLoader />}
						{!loading && error && (
							<HttpError onRetry={() => get()} />
						)}
						{data && !loading && !error && (
							<WpPost body={data.body} />
						)}
					</>
				)}
				<div className={styles.links}>
					<a className={styles.createdBy} href="https://loool.com" target="__blank">
						<IonIcon className={classNames(styles.createdByLogo, 'is-large')} icon={looolLogoIcon}/>
						<span>
							{t('pages.info.created_by')} &nbsp;
							<span className={styles.createdByLooolText}>
								LOOOL
							</span>
							<span className={styles.createdByTrademarkSymbol}>
								®
							</span>
						</span>
						<IonIcon className={classNames(styles.createdByIcon, 'is-small')} icon={externalLinkIcon}/>
					</a>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default Info;
