import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonContent,
	IonFooter,
	IonIcon,
	IonPage,
	IonToolbar,
	useIonToast,
	useIonViewWillEnter,
	useIonViewWillLeave,
} from "@ionic/react";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet";
import InfiniteText from "../components/InfiniteText";
import MediaPlayer from "../components/MediaPlayer/MediaPlayer";
import useStop from "../hooks/useStop";
import backBtnIcon from "../theme/icons/arrow-left.svg";
import closeIcon from "../theme/icons/close.svg";
import likeIcon from "../theme/icons/like.svg";
import likedIcon from "../theme/icons/liked.svg";
import shevronCircleUpIcon from '../theme/icons/shevron-up-circle.svg';
import bookIcon from '../theme/icons/book.svg';
import styles from "./Stop.module.css";

/**
 * Page where user can list/watch the stop's media content and read more about the stop
 */
const Stop: React.FC = () => {
	const { t } = useTranslation();
	const { stopNumber } = useParams<{ stopNumber: string }>();
	const { stop, toggleLike } = useStop(stopNumber);
	const [bottomSheetIsHidden, setBottomSheetIsHidden] = useState(true);
	const [isBottomHidden, setIsBottomHidden] = useState(true);
	const [isLikeButtonDisabled, setIsLikeButtonDisabled] = useState(false);
	const [presentToast, dismissToast] = useIonToast();

	const playPauseWrapperRef = useRef<HTMLDivElement>(null);
	const seekBarWrapperRef = useRef<HTMLDivElement>(null);
	const playHeadWrapperRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!stop) {
			return;
		}

		document.title = stop.title;
	}, [stop]);

	// Collapse the bottom sheet on view leave
	// and hide the bottom elements
	useIonViewWillLeave(() => {
		setBottomSheetIsHidden(true);
		setIsBottomHidden(true);
	});

	// Show bottom elements on view enter
	useIonViewWillEnter(() => {
		setIsBottomHidden(false);
	});

	/**
	 * Toggles like on the stop if a user has connection
	 * otherwise shows the error message explaining that a connection
	 * is required to toggle like.
	 */
	const handleLikeButtonClick = async () => {
		// Disable button while the request is in progress
		setIsLikeButtonDisabled(true);
		// Show message about this functionality is
		// only available online
		if (!navigator.onLine) {
			presentToast({
				position: "top",
				color: "danger",
				buttons: [{ icon: closeIcon, handler: () => dismissToast() }],
				message: t("pages.stop.like_connection_required_error_message"),
			});
			setIsLikeButtonDisabled(false);
			return;
		}

		const isRequestSucceeded = await toggleLike();

		// Notify a user about failed request
		if (!isRequestSucceeded) {
			presentToast({
				position: "top",
				color: "danger",
				buttons: [{ icon: closeIcon, handler: () => dismissToast() }],
				message: t("common.http_error"),
			});
		}

		// Enable button after the request is finished
		setIsLikeButtonDisabled(false);
	};

	const onBottomSheetDismiss = () => {
		setBottomSheetIsHidden(true);
	}

	const onTitleClick = () => {
		setBottomSheetIsHidden(false);
	}

	return (
		<IonPage>
			<IonContent
				fullscreen={false}
				scrollY={false}
				style={{
					"--background": `url(${stop?.cover.url})`,
				}}
				className={classNames({
					[styles.content]: true,
					[styles.hasVideo]: stop?.media_file.type === "video",
				})}
			>
				<IonToolbar className={styles.topToolbar} slot="fixed">
					<IonButtons slot="start">
						<IonBackButton
							aria-label={t("common.buttons.back")}
							data-cy="back-btn"
							defaultHref="/tabs/tours"
							text=""
							icon={backBtnIcon}
						/>
					</IonButtons>
					<IonButtons slot="end">
						<IonButton
							className={classNames({
								[styles.likeButton]: true,
								[styles.isLiked]: stop?.like?.token.length,
							})}
							onClick={handleLikeButtonClick}
							disabled={isLikeButtonDisabled}
							aria-label={
								stop?.like.token.length
									? t("pages.stop.like_button_remove_like")
									: t("pages.stop.like_button_add_like")
							}
						>
							<IonIcon
								className="is-small"
								slot="icon-only"
								icon={likeIcon}
							></IonIcon>
							<IonIcon
								className={styles.likedIcon}
								slot="icon-only"
								icon={likedIcon}
							></IonIcon>
						</IonButton>
					</IonButtons>
				</IonToolbar>
				<MediaPlayer
					url={stop?.media_file?.url ?? ""}
					playPauseButtonNodeContainer={playPauseWrapperRef.current}
					seekBarNodeContainer={seekBarWrapperRef.current}
					playHeadPositionNodeContainer={playHeadWrapperRef.current}
				/>
				<BottomSheet
					className={classNames({
						[styles.bottomSheet]: true,
					})}
					blocking={false}
					open={!bottomSheetIsHidden}
					onDismiss={onBottomSheetDismiss}
          snapPoints={({ minHeight }) => minHeight}
					header={
						<div className={styles.bottomSheetHeaderContent}>
							<h1 className={styles.title}>
								<InfiniteText text={stop?.title || ""} />
							</h1>
							<IonButton
								className={styles.closeBottomSheetButton}
								onClick={onBottomSheetDismiss}
							>
								<IonIcon
									class="is-medium"
									icon={shevronCircleUpIcon}
									color="light"
									slot="icon-only"
							/>
							</IonButton>
						</div>
					}
				>
					<div
						className={classNames({
							[styles.bottomSheetScrollContent]: true,
							[styles.hasNoContent]: !(
								stop?.descrizione || ""
							).trim().length,
						})}
					>
						<p className={styles.description}>
							{stop?.descrizione}
						</p>
					</div>
				</BottomSheet>
				{/* Render footer on the same level as bottom sheet */}
				{/* to be able use the same z index context */}
				{ReactDOM.createPortal(
						<IonButton
							className={classNames({
								[styles.openBottomSheetButton]: true,
								[styles.isHidden]: !bottomSheetIsHidden,
								[styles.isHiddenOutScreen]: isBottomHidden,
							})}
							expand="full"
							onClick={onTitleClick}
						>
							<span><InfiniteText text={stop?.title || ""} /></span>
							<IonIcon
								className="is-medium"
								icon={bookIcon}
								color="light"
								slot="end"
							/>
						</IonButton>,
						document.body
				)}
				{ReactDOM.createPortal(
					<IonFooter
						className={classNames({
							[styles.footer]: true,
							[styles.isHiddenOutScreen]: isBottomHidden,
						})}
					>
						<IonToolbar className={styles.toolbarBottom}>
							<div className={styles.mediaPlayerControls}>
								<div ref={playPauseWrapperRef} />
								<div ref={seekBarWrapperRef} />
								<div
									className={styles.playHeadPositionWrapper}
									ref={playHeadWrapperRef}
								/>
							</div>
						</IonToolbar>
					</IonFooter>,
					document.body
				)}
			</IonContent>
		</IonPage>
	);
};

export default Stop;
