import { IonButton, IonIcon } from '@ionic/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import starIcon from '../../theme/icons/star.svg';
import starFilledIcon from '../../theme/icons/star-filled.svg';
import checkMarkIcon from '../../theme/icons/check-mark.svg';
import styles from './Rating.module.css';

interface RatingProps {
	title: string;
}

const Rating: React.FC<RatingProps> = ({ title }) => {
	const { t } = useTranslation();
	const [ rate, setRate ] = useState(0);
	const [ isRated, setIsRated ] = useState(false);

	return (
		<div
			className={classNames({
				[styles.container]: true,
				[styles.isRated]: isRated
			})}
		>
			<div className={styles.title}>{title}</div>
			<div className={styles.buttons}>
				<div className={styles.stars}>
					{[ 1, 2, 3, 4, 5 ].map((n) => (
						<button
							className={classNames({
								[styles.star]: true,
								[styles.isActive]: n <= rate
							})}
							key={n}
							onClick={() => {
								if (!isRated) {
									setRate(n)
								}
							}}
						>
							<IonIcon className="is-medium" color="dark" icon={starIcon} />
							<IonIcon className="is-medium" color="dark" icon={starFilledIcon} />
						</button>
					))}
				</div>
				<IonButton
					className={classNames({
						[styles.button]: true,
						[styles.isDisabled]: rate < 1
					})}
					fill="outline"
					size="small"
					disabled={rate < 1}
					onClick={() => setIsRated(true)}
				>
					<span>{t('components.rating.button')}</span>
					<IonIcon class="is-small" icon={checkMarkIcon} />
				</IonButton>
			</div>
		</div>
	);
};

export default Rating;
