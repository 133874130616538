import React from "react";
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { useTranslation } from "react-i18next";
import Rating from "../components/Rating/Rating";
import styles from './Ratings.module.css';

const Ratings = () => {
  const { t } = useTranslation();
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle slot="start">{t("pages.ratings.title")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <p>
          I tuoi feedback ci aiutano a migliorare la tua esperienza. <b>Grazie!</b>
        </p>
        <ul className={styles.list}>
          <li className={styles.item}>
            <Rating title={t('pages.ratings.rating_1.title')}></Rating>
          </li>
          <li className={styles.item}>
            <Rating title={t('pages.ratings.rating_2.title')}></Rating>
          </li>
          <li className={styles.item}>
            <Rating title={t('pages.ratings.rating_3.title')}></Rating>
          </li>
          <li className={styles.item}>
            <Rating title={t('pages.ratings.rating_4.title')}></Rating>
          </li>
          <li className={styles.item}>
            <Rating title={t('pages.ratings.rating_5.title')}></Rating>
          </li>
        </ul>
      </IonContent>
    </IonPage>
  )
};

export default Ratings;