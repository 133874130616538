import { IonIcon } from "@ionic/react";
import playStopIcon from "../../theme/icons/play-stop.svg";
import chronometerIcon from '../../theme/icons/chronometer.svg';
import InfiniteText from "../InfiniteText";
import styles from "./StopPreview.module.css";
import classNames from "classnames";

type StopCardProps = {
	thumbnail: {
		url: string;
		alt: string;
	};
	title: string;
	artistName: string;
	/**
	 * Media file duration
	 */
	duration?: string;
	/**
	 * Stop's number
	 */
	number: string | number;
};

/**
 * Represents stop's preview which renders thumbnail, title, artist name, media file duration and number
 */
const StopPreview: React.FC<StopCardProps> = ({
	thumbnail,
	title,
	artistName,
	duration,
	number,
}) => {
	return (
		<div className={styles.preview}>
			<div className={styles.thumbnail}>{ number }</div>
			<div className={styles.body}>
				<h2 className={styles.title}>
					<InfiniteText text={title} />
				</h2>
				<div className={styles.meta}>
					<p className={styles.duration}>
						{duration && duration.length && (
							<IonIcon
								className="is-small"
								icon={chronometerIcon}
								color="dark"
							/>
						)}
						<span className={styles.durationText}>{duration}</span>
					</p>
				</div>
			</div>
			<IonIcon
				className={classNames(styles.playIcon, 'is-small')}
				icon={playStopIcon}
				color="dark"
			/>
		</div>
	);
};

export default StopPreview;
