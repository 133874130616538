import { IonPage, useIonViewDidEnter } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import useGetTours from "../hooks/useGetTours";

/**
 * Renders available tours
 */
const Tours: React.FC<RouteComponentProps> = ({ history, match }) => {
	const { t, i18n } = useTranslation();
	const { tours } = useGetTours();

	useIonViewDidEnter(() => {
		document.title = t("pages.tours.title");
	});

	useIonViewDidEnter(() => {
		if (i18n.language === 'it') {
			history.replace('/tabs/tours/33');
		} else if (i18n.language === 'en') {
			history.replace('/tabs/tours/34');
		} else if (i18n.language === 'fr') {
			history.replace('/tabs/tours/35');
		} else {
			history.replace('/tabs/tours');
		}
	}, [i18n.language, history]);

	return (
		<IonPage>
			{/* redirect user to tour directly... */}
		</IonPage>
	);
};

export default Tours;
