import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { IonIcon } from "@ionic/react";
import playIcon from "../../theme/icons/play.svg";
import pauseIcon from "../../theme/icons/pause.svg";
import styles from "./PlayPauseOverlayIcon.module.css";

interface PlayPauseOverlayIconProps {
	isPlaying: boolean;
}

const PlayPauseOverlayIcon: React.FC<PlayPauseOverlayIconProps> = ({
	isPlaying,
}) => {
	const [showIcons, setShowIcons] = useState(true);
	const hideIconsTimer = useRef<any>();

	useEffect(() => {
		clearTimeout(hideIconsTimer.current);
		if (isPlaying) {
			hideIconsTimer.current = setTimeout(() => {
				setShowIcons(false);
			}, 1e3);
		} else if (!isPlaying) {
			setShowIcons(true);
		}
	}, [isPlaying]);

	return (
		<div
			className={classNames({
				[styles.playPauseWrapper]: true,
				[styles.fadeout]: !showIcons,
			})}
		>
			<IonIcon
				className={classNames({
					"is-large": true,
					[styles.playIcon]: true,
					[styles.isHidden]: isPlaying,
				})}
				icon={playIcon}
				color={"light"}
			/>
			<IonIcon
				className={classNames({
					"is-large": true,
					[styles.pauseIcon]: true,
					[styles.isHidden]: !isPlaying,
				})}
				icon={pauseIcon}
				color={"light"}
			/>
		</div>
	);
};

export default PlayPauseOverlayIcon;
