import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar
} from "@ionic/react";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useParams } from "react-router";
import { Link } from "react-router-dom";
import HeaderSearchBar from "../components/HeaderSearchBar";
import StopPreview from "../components/StopPreview";
import useGetTour from "../hooks/useGetTour";
import styles from "./Tour.module.css";

/**
 * Renders a tour with the provided id parameter provided in the url
 */
const Tour: React.FC<RouteComponentProps> = () => {
	const { t } = useTranslation();
	const { tourId } = useParams<{ tourId: string }>();
	const { tour, tourStops } = useGetTour(tourId);
	const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);
	const [searchKeywords, setSearchKeywords] = useState("");

	useEffect(() => {
		if (!tour) {
			return;
		}

		document.title = tour.name;
	}, [tour]);

	/**
	 * Returns all stops in search keywords is empty string
	 * otherwise returns stop that match the search keywords
	 */
	const getStops = () => {
		const found = tourStops.filter((stop) => {
			const title = stop.title ?? "";
			const artistName = stop.nome_artista ?? "";
			const isMatch =
				title.toLowerCase().indexOf(searchKeywords.toLowerCase()) >
					-1 ||
				artistName.toLowerCase().indexOf(searchKeywords.toLowerCase()) >
					-1;
			return isMatch;
		});

		return found;
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle
						className={classNames({
							[styles.title]: true,
							[styles.isHidden]: isSearchBarOpen,
						})}
						slot="start"
					>
						{tour?.name}
					</IonTitle>
					<HeaderSearchBar
						placeholder={t("pages.tour.search_bar_placeholder")}
						slot="secondary"
						style={{
							alignSelf: "center",
						}}
						onOpen={() => setIsSearchBarOpen(true)}
						onClose={() => {
							setIsSearchBarOpen(false);
							setSearchKeywords("");
						}}
						onInput={(keywords) => setSearchKeywords(keywords)}
					/>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<div className={styles.list}>
					{getStops().map((stop) => (
						<Link
							className={styles.stopLink}
							key={stop.ID}
							to={`/tabs/stop/${stop.number}`}
						>
							<StopPreview
								thumbnail={{
									url: stop.cover.url,
									alt: stop.cover.alt,
								}}
								title={stop.title}
								artistName={stop.nome_artista}
								duration={stop.durata}
								number={stop.number}
							/>
						</Link>
					))}
				</div>
			</IonContent>
		</IonPage>
	);
};

export default Tour;
