import { useIonViewDidEnter, useIonViewDidLeave, useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { animated, useSpring } from "react-spring";

const GAP_BETWEEN_TEXT_AND_COPY = 32;

interface InfiniteTextProps {
	/**
	 * Text content to render
	 */
	text: string;
}

/**
 * Text carousel in case if text is overflows a container (text is wider than container)
 * otherwise renders the text as normal text
 * --------------------------------------------------------------------------------------
 *  Known issue, the text container width is bigger than it should be:
 *
 * 	- In case InfiniteText has parent or grand parent with `display: flex` then
 * 	  set `min-width: 0 or 0px;` on the closest parent or grand parent with the `display: flex` rule
 *  - In case InfiniteText has parent or grand parent with `display:grid` then
 *    set `min-width: 0 or 0px;` on the column where InfiniteText is placed
 * 	  or set `grid-template-columns: ...other_definitions minmax(0, column_max_width)`;
 */
const InfiniteText: React.FC<InfiniteTextProps> = ({ text }) => {
	const contentRef = useRef<HTMLSpanElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);
	const [textOverflows, setTextOverflows] = useState(false);

	const [styles, api] = useSpring(() => ({
		display: "block",
		transform: `translate3d(0px, 0, 0)`,
	}));

	const startAnimation = useCallback(() => {
		if (!contentRef.current || !containerRef.current) {
				return;
			}

		const isTextOverflows =
			containerRef.current.getBoundingClientRect().width <
			contentRef.current.getBoundingClientRect().width -
			GAP_BETWEEN_TEXT_AND_COPY;

		// Start animation only in case the text is not fully visible
		if (isTextOverflows) {
			const t =
				(contentRef.current.getBoundingClientRect().width / 50) * 1000;
			const dx = contentRef.current.getBoundingClientRect().width;

			api.start({
				from: { transform: `translate3d(0px, 0, 0)` },
				to: { transform: `translate3d(-${dx}px, 0, 0)` },
				delay: 2000,
				config: {
					duration: t,
				},
				loop: true,
			});
		}

		setTextOverflows(isTextOverflows);
	}, [api])

	const stopAnimation = useCallback(() => {
		api.start({
			reset: true,
			immediate: true,
		});
		api.stop();
	}, [api])

	useEffect(() => {
		startAnimation()
		return () => {
			stopAnimation();
		}
	}, [startAnimation, stopAnimation]);

	useIonViewWillEnter(() => {
		startAnimation();
	}, [startAnimation])

	useIonViewDidLeave(() => {
		stopAnimation();
	}, [stopAnimation])

	return (
		<span
			style={{
				display: "block",
				overflow: "hidden",
				whiteSpace: "nowrap",
			}}
			ref={containerRef}
			aria-label={text}
		>
			<animated.span style={styles}>
				<span
					ref={contentRef}
					style={{ paddingRight: GAP_BETWEEN_TEXT_AND_COPY }}
					aria-hidden="true"
				>
					{text}
				</span>
				<span hidden={!textOverflows} aria-hidden="true">
					{text}
				</span>
			</animated.span>
		</span>
	);
};

export default InfiniteText;
