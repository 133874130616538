import { Range, getTrackBackground } from "react-range";
import { fmtMSS } from "./utils";
import styles from "./SeekBar.module.css";

type props = {
	duration: number;
	playedSeconds: number;
	onChange?: (secondsToSeek: number) => void;
	onRelease?: () => void;
};

const SeekBar: React.FC<props> = ({
	duration,
	playedSeconds,
	onChange = () => {},
	onRelease = () => {},
}) => (
	<div
		style={{
			// add spacing that is equavalent to a thumb's horizontal trasform translate value
			// so it does not overlaps with other elements
			paddingLeft: "5px",
			paddingRight: "5px",
		}}
	>
		<Range
			min={0}
			max={duration}
			values={[playedSeconds]}
			renderThumb={({ props }) => {
				return (
					<div
						{...props}
						style={{
							...props.style,
							borderRadius: "50%",
							border: "2px solid var(--ion-color-light)",
							height: "10px",
							width: "10px",
							backgroundColor: "var(--ion-color-dark)",
							outline: "none",
						}}
						// @ts-ignore
						aria-valuemin={fmtMSS(props["aria-valuemin"])}
						// @ts-ignore
						aria-valuenow={fmtMSS(props["aria-valuenow"])}
						// @ts-ignore
						aria-valuemax={fmtMSS(props["aria-valuemax"])}
					/>
				);
			}}
			renderTrack={({ props, children }) => (
				<div
					onMouseDown={props.onMouseDown}
					onTouchStart={props.onTouchStart}
					style={{
						...props.style,
						height: "24px",
						display: "flex",
						width: "100%",
					}}
				>
					<div
						ref={props.ref}
						style={{
							width: "100%",
							height: "2px",
							borderRadius: "4px",
							background: getTrackBackground({
								values: [playedSeconds],
								colors: [
									"var(--ion-color-light)",
									"rgba(var(--ion-color-light-rgb), 0.25)",
								],
								min: 0,
								max: duration,
							}),
							alignSelf: "center",
						}}
					>
						{children}
					</div>
				</div>
			)}
			onChange={([value]) => onChange(value)}
			onFinalChange={() => onRelease()}
		/>
	</div>
);

export default SeekBar;
