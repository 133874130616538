import {
	IonIcon,
	IonRouterOutlet,
	IonTabBar,
	IonTabButton,
	IonTabs,
} from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import infoIcon from "../theme/icons/info.svg";
import keypadIcon from "../theme/icons/keypad.svg";
import languageIcon from "../theme/icons/language.svg";
import tourIcon from "../theme/icons/tour.svg";
import starIcon from '../theme/icons/star.svg';
import ChangeLanguage from "./ChangeLanguage";
import Info from "./Info";
import SearchStop from "./SearchStop";
import Stop from "./Stop";
import Tour from "./Tour";
import Tours from "./Tours";
import Ratings from './Ratings';

const Tabs: React.FC<RouteComponentProps> = ({ match }) => {
	const { t } = useTranslation();

	return (
		<IonTabs>
			<IonRouterOutlet>
				<Redirect exact from={match.url} to={`${match.url}/tours`} />
				<Route
					path={`${match.url}/select-language`}
					component={ChangeLanguage}
				/>
				<Route exact path={`${match.url}/tours`} component={Tours} />
				<Route
					exact
					path={`${match.url}/tours/:tourId`}
					component={Tour}
				/>
				<Route
					exact
					path={`${match.url}/search-stop`}
					component={SearchStop}
				/>
				<Route
					exact
					path={`${match.url}/stop/:stopNumber`}
					component={Stop}
				/>
				<Route exact path={`${match.url}/ratings`} component={Ratings} />
				<Route exact path={`${match.url}/info`} component={Info} />
			</IonRouterOutlet>
			<IonTabBar slot="bottom">
				<IonTabButton
					tab="change-language"
					aria-label={t("pages.tabs.buttons.change_language")}
					href={`${match.url}/select-language`}
				>
					<IonIcon icon={languageIcon} />
				</IonTabButton>
				<IonTabButton
					tab="tours"
					href={`${match.url}/tours`}
					aria-label={t("pages.tabs.buttons.tours")}
				>
					<IonIcon icon={tourIcon} />
				</IonTabButton>
				<IonTabButton
					tab="search-stop"
					href={`${match.url}/search-stop`}
					aria-label={t("pages.tabs.buttons.search_stop")}
				>
					<IonIcon icon={keypadIcon} />
				</IonTabButton>
				<IonTabButton
					tab="ratings"
					href={`${match.url}/ratings`}
					aria-label={t("pages.tabs.buttons.ratings")}
				>
					<IonIcon icon={starIcon} />
				</IonTabButton>
				<IonTabButton
					tab="info"
					href={`${match.url}/info`}
					aria-label={t("pages.tabs.buttons.info")}
				>
					<IonIcon icon={infoIcon} />
				</IonTabButton>
			</IonTabBar>
		</IonTabs>
	);
};

export default Tabs;
